<template>
	<a-layout id="components-layout-demo-custom-trigger">
		<a-layout-sider v-model="collapsed" :trigger="null" collapsible>
			<div class="logo" :class="{'wu': collapsed}">
				<span>纸硬币监管平台</span>
			</div>
			<a-menu theme="dark" mode="inline" :default-selected-keys="['111']" :default-open-keys="['sub1']">
				<a-menu-item key="1" @click="linkPage('/home')" v-if="isHome">
					<a-icon type="bank" />
					<b class="titb" style="margin-left:4px">工作台</b>
				</a-menu-item>
				<a-sub-menu v-for="(data) in menus" :key="data.title">
					<span slot="title">
						<a-icon :type="data.icon" /> <b class="titb">{{data.title}}</b> </span>
					<a-menu-item @click="linkPage(item.url)" v-for="(item) in data.childers" :key="item.name"> {{item.name}}
					</a-menu-item>
				</a-sub-menu>
			</a-menu>
		</a-layout-sider>
		<a-layout>
			<a-layout-header style="background: #fff; padding: 0" class="layout-header">
				<a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="() => (collapsed = !collapsed)" />
				<a-dropdown>
					<div class="user-info">
						<div class="user-name">{{username}}</div>
						<div class="user-avater">
							<img src="../../assets/img.png" />
						</div>
					</div>
					<a-menu slot="overlay">
						<a-menu-item>
							<a @click="updatePassword">修改个人密码</a>
						</a-menu-item>
						<a-menu-item>
							<a @click="logut">退出登录</a>
						</a-menu-item>
					</a-menu>
				</a-dropdown>

			</a-layout-header>

			<!-- <div class="header-tabs">
        <a-tabs
          v-model="activeKey"
          hide-add
          type="editable-card"
          @edit="onEdit"
        >
          <a-tab-pane
            v-for="pane in panes"
            :key="pane.key"
            :tab="pane.title"
            :closable="pane.closable"
          >
          </a-tab-pane>
        </a-tabs>
      </div> -->

			<a-layout-content :style="{
          margin: '24px 16px',
          minHeight: '280px',
        }">
				<router-view />
			</a-layout-content>
		</a-layout>
	</a-layout>
</template>
<script>
	export default {
		data() {
			const panes = [{
					title: "设备管理",
					content: "Content of Tab 1",
					key: "1"
				},
				{
					title: "故障管理",
					content: "Content of Tab 2",
					key: "2"
				},
			];
			return {
				activeKey: panes[0].key,
				panes,
				newTabIndex: 0,
				collapsed: false,
				menuIcons: {
					'监控功能': 'desktop',
					'用户信息': 'user',
					'文章管理': 'container',
					'预约服务': 'schedule',
					'系统管理': 'setting',
				},
				isHome: false,
				menus: [{
						title: '监控功能',
						icon: 'desktop',
						childers: [{
								name: '设备管理',
								url: '/equipment-manag'
							},
{
								name: '设备状态监控',
								url: '/equipment-status'
							},
							{
								name: '账面管理',
								url: '/book-manag'
							},
							{
								name: '现金流转管理',
								url: '/cash-flow-manag'
							},
							{
								name: '清机加钞管理',
								url: '/clean-machine-manag'
							},

							{
								name: '钞箱信息',
								url: '/cash-box-info'
							},

							{
								name: '出入钞统计',
								url: '/banknotes'
							},
							{
								name: '设备信息统计',
								url: '/equipment-info'
							},

						]
					}, 
					{
						title: '用户信息',
						icon: 'user',
						childers: [{
							url: '/member-manag',
							name: '用户管理',
						}, {
							url: '/member-audit-manag',
							name: '投诉与建议',
						}]
					},
					{
						title: '文章管理',
						icon: 'container',
						childers: [{
							url: '/articles',
							name: '文章列表',
						}, {
							url: '/article-class',
							name: '文章分类',
						}]
					},
					{
						title: '预约服务',
						icon: 'schedule',
						childers: [{
								url: '/order-manag',
								name: '预约人民币',
							},
							{
								url: '/order-manag1',
								name: '预约硬币',
							},
							{
								url: '/order-manag2',
								name: '预约纸币',
							}
						]
					},
					{
						title: '系统管理',
						icon: 'setting',
						childers: [{
							url: '/user-manag',
							name: '用户管理',
						}, {
							url: '/department-manag',
							name: '部门管理',
						}, {
							url: '/role-manag',
							name: '角色管理',
						}, {
							url: '/bank-manag',
							name: '银行类型',
						}, {
							url: '/bank-outlets',
							name: '银行网点',
						}, {
							url: '/vendor-manag',
							name: '厂商管理',
						}, {
							url: '/equipment-types',
							name: '设备类型',
						}, {
							url: '/ads',
							name: '轮播图管理',
						}, {
							url: '/router-manag',
							name: '路由管理',
						}, {
							url: '/change-password',
							name: '修改密码',
						}]
					}
				],
				username: ''
			};
		},
		created() {
			let authority = JSON.parse(localStorage.getItem('authority'))
			let newAuthority = []
			authority.forEach((v) => {
				if (v.router == '/home') {
					this.isHome = true
				}
				if (v.router != '/home') {
					newAuthority.push(v)
				}
			})
			this.menus = newAuthority.map((v) => {
				return {
					title: v.router_name,
					icon: this.menuIcons[v.router_name],
					childers: v.list ? v.list.map((vi) => {
						return {
							url: vi.router,
							name: vi.router_name
						}
					}) : []
				}
			})
			this.username = localStorage.getItem('username')
		},
		methods: {
			onEdit() {},
			linkPage(url) {
				this.$router.push({
					path: url
				})
			},
			logut () {
				localStorage.removeItem('username')
				localStorage.removeItem('token')
				localStorage.removeItem('authority')
				this.$router.push({
					path: '/login'
				})
			},
			updatePassword () {
				this.$router.push({
					path: '/change-password'
				})
			}
		},
	};
</script>
<style lang="less">
	.ant-layout-sider {
		background-color: #fff !important;
		.ant-menu-dark {
			background-color: #fff !important;
			    color: rgba(0, 0, 0, 0.65) !important;
				.ant-menu-item {
					color: #000 !important;
				}
		}
		.ant-menu-dark .ant-menu-item-selected .anticon {
			color: #000 !important;
		}
		.ant-menu-submenu-title:hover {
			color: #000 !important;
		}
		.ant-menu-dark .ant-menu-submenu-open {
			color: #000 !important;
		}
		.ant-menu-dark .ant-menu-inline.ant-menu-sub {
			background-color: #fff !important;
			box-shadow: none;
		}
		.ant-menu.ant-menu-dark .ant-menu-item-selected {
			    background-color: #e6f7ff !important;
		}
		.ant-menu-submenu-arrow {
			&:after {
				 background: rgba(0, 0, 0, 0.65) !important;
			}
			&:before {
				 background: rgba(0, 0, 0, 0.65) !important;
			}
		}
		.ant-menu-submenu-selected {
			color: #000 !important;
			  
		}
		
	}
	.ant-menu-dark, .ant-menu-dark .ant-menu-sub {
		background-color: #fff !important;
	}
	.ant-menu-dark .ant-menu-item-selected {
		color: #000 !important;
		background-color: #e6f7ff !important;
	}
	.ant-menu-dark .ant-menu-item {
		  color: rgba(0, 0, 0, 0.65) !important;
	}
	#components-layout-demo-custom-trigger .trigger {
		font-size: 18px;
		line-height: 64px;
		padding: 0 24px;
		cursor: pointer;
		transition: color 0.3s;
	}

	#components-layout-demo-custom-trigger .trigger:hover {
		color: #1890ff;
	}

	#components-layout-demo-custom-trigger .logo {
		height: 32px;
		background: rgba(255, 255, 255, 0.2);
		margin: 10px 12px !important;
	}

	#components-layout-demo-custom-trigger {
		.logo {
			color: #fff;
			font-weight: 700;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 20px;
			background-color: transparent !important;
			background-image: url(../../assets/logo.png);
			background-repeat:no-repeat;
			width: 175px;
			height: 43px;
			color: #000;
			padding-left: 30px;
			span {
				position: relative;
			}
			&.wu {
				width: 40px;
				position: relative;
				left: 13px;
				span {
					display: none;
				}
			}
		}

		.header-tabs {
			margin: 24px 16px;
			margin-bottom: 0px;

			.ant-tabs-bar {
				margin-bottom: 0px;
			}
		}

		.layout-header {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.user-info {
				display: flex;
				padding-right: 20px;
				align-items: center;
				cursor: pointer;

				.user-name {
					margin-right: 10px;
				}

				.user-avater {
					width: 50px;
					height: 50px;
					background-color: #ccc;
					border-radius: 50px;
					img {
						display: block;
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}

	.ant-menu-dark.ant-menu-inline {
		overflow: auto;
		height: calc(100% - 100px);
	}

	/* 设置滚动条的样式 */
	.ant-menu-dark.ant-menu-inline::-webkit-scrollbar {
		width: 1px;
	}

	/* 滚动槽 */
	.ant-menu-dark.ant-menu-inline::-webkit-scrollbar-track {
		-webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.0);
		border-radius: 1px;
	}

	/* 滚动条滑块 */
	.ant-menu-dark.ant-menu-inline::-webkit-scrollbar-thumb {
		border-radius: 1px;
		background: rgba(0, 0, 0, 0.0);
		-webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
	}

	.ant-menu-dark.ant-menu-inline::-webkit-scrollbar-thumb:window-inactive {
		background: rgba(255, 0, 0, 0.0);
	}
	
	
</style>
